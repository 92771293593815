<template>
  <div class="Enter-box varietyS">
    <div class="Essential">
      <div class="Essential-box">
        <ul class="Essential-ul">
          <li class="Essential-li">全部菜品</li>
          <li @click="structure()" class="">菜品分类</li>
        </ul>
      </div>
      <div class="Essential-state">
        <div class="Essential-state-box">
          <ul>
            <li @click="roleS"><i></i><span>新增菜品</span></li>
            <li @click="card"><i></i><span>新增套餐</span></li>
            <li @click="PiLiangShanChu"><i></i><span>批量删除</span></li>
            <li @click="import_cat_file_show = true">
              <i></i><span>导入菜品</span>
            </li>
            <li @click="export_cat_file_show = true">
              <i></i><span>导出数据</span>
            </li>
          </ul>

              <div class="print_box_top">
                <div class="print_txt">开启会员价</div>
                <el-radio v-model="print_serch_huyuan" label="1" @change="update_ent_sys_set_huiyuan" >是</el-radio>
                <el-radio v-model="print_serch_huyuan" label="0" @change="update_ent_sys_set_huiyuan" >否</el-radio>
              </div>

              <div class="print_box_top">
                <div class="print_txt">开启早午晚市价</div>
                <el-radio v-model="print_serch_zww" label="1" @change="update_ent_sys_set_zww" >是</el-radio>
                <el-radio v-model="print_serch_zww" label="0" @change="update_ent_sys_set_zww" >否</el-radio>
              </div>

          <div class="message-search">
            <i></i>
            <!-- @keyup.native="searchAllCompany" -->
            <el-input v-model="nameA" tpye="text" placeholder="搜索菜品或套餐" @input="searchAllCompany">
            </el-input>
          </div>
        </div>
        <div class="message-section">
          <div class="message-section-top">
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" height="450"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="155"> </el-table-column>
              <el-table-column prop="prod_pic" label="图片" width="110">
                <template slot-scope="scope">
                  <img v-if="scope.row.prod_pic" :src="scope.row.prod_pic.split(' ')[0]" width="50px" height="50px" />
                  <div v-else>无图</div>
                </template>
              </el-table-column>
              <el-table-column prop="prod_code" label="编号" width="140">
              </el-table-column>
              <el-table-column prop="prod_name" label="菜品名称" width="200">
              </el-table-column>
              <el-table-column prop="cat_name" label="类型" width="150">
              </el-table-column>
              <el-table-column prop="site_name" label="仓库" width="150">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.site_name ? scope.row.site_name : '无' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="spec" label="规格" width="140">
                <template slot-scope="scope">
                  <div v-if="scope.row.prod_type == '2'">
                    {{ scope.row.spec }}
                  </div>
                  <div v-if="scope.row.prod_type == '1'">
                    {{
                      scope.row.spec_list[0]
                        ? scope.row.spec_list[0].prod_spec
                        : ''
                    }}
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="spec_list[0].spec_title" label="规格" width="140" v-else>
              </el-table-column> -->
              <el-table-column prop="unit" label="单位" width="140">
              </el-table-column>
              <!-- <el-table-column prop="price_uniform" label="价格" width="140">
              </el-table-column>
              <el-table-column prop="price_member" label="会员价" width="140">
              </el-table-column> -->
              <!-- <el-table-column prop="spec_list[0].price_uniform" label="价格" width="140">
              </el-table-column> prop="spec_list[0].price_member" -->

              <el-table-column label="统一价格" width="160">
                <template slot-scope="scope">
                  <div v-if="scope.row.prod_type == '2'">
                    <!-- 套餐 -->
                    {{ scope.row.price_uniform ? scope.row.price_uniform : '' }}
                  </div>
                  <div v-if="scope.row.prod_type == '1'">
                    <!-- 菜品 -->
                    <div v-if="scope.row.spec_list[0].price_uniform == ''">
                      <span v-if="scope.row.spec_list[0].price_morning != ''">早:
                        {{ scope.row.spec_list[0].price_morning }} </span>&nbsp; &nbsp;<span
                        v-if="scope.row.spec_list[0].price_noon != ''">午: {{ scope.row.spec_list[0].price_noon }}
                      </span>&nbsp;&nbsp;<span v-if="scope.row.spec_list[0].price_night != ''">晚:
                        {{ scope.row.spec_list[0].price_night }}</span>
                    </div>
                    <div v-if="scope.row.spec_list[0].price_uniform != ''">
                      {{
                        scope.row.spec_list[0]
                          ? scope.row.spec_list[0].price_uniform
                          : ''
                      }}
                    </div>
                  </div>
                </template>
              </el-table-column>

              <!-- earl: 2021-09-08 按周经理图片处理
                
              <el-table-column prop="date_valid" label="有效天数" width="140">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.date_valid ? scope.row.date_valid : '无' }}
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column prop="price_member" label="会员价" width="140">
                <template slot-scope="scope">
                  <div v-if="scope.row.prod_type == '2'">
                    <!-- 套餐 -->
                    {{ scope.row.price_member ? scope.row.price_member : '' }}
                  </div>
                  <div v-if="scope.row.prod_type == '1'">
                    {{ scope.row.spec_list[0].price_member ? scope.row.spec_list[0].price_member : '' }}
                  </div>
                  
                </template>
              </el-table-column>
             <!-- earl: 2021-09-08 按周经理图片处理 END -->

              <el-table-column prop="handle" label="操作" width="340">
                <template slot-scope="scope">
                  <div class="handle">
                    <!-- v-show true显示 false隐藏 ||!scope.row.spec_list[0].price_member=='' -->
                    <div @click="card_cai(scope.$index, tableData)" v-if="
                        scope.row.flag_cost == 1
                      ">
                      <img :src="require('../../../assets/img/cai1.png')" alt="" />
                      <p>成本卡</p>
                    </div>
                    <!-- <div v-show="scope.row.prod_type==1?scope.row.spec_list[0]?scope.row.spec_list[0].prod_spec=='':true:true" style="width: 71px;"></div> -->
                    <!-- {{scope.row.prod_type==1?' 套餐':'原材料'}} -->
                    <div v-else style="width: 71px;"></div>
                    <div @click="BianJi_Btn(scope.$index, tableData)">
                      <img :src="require('../../../assets/img/cai2.png')" alt="" />
                      <p>编辑</p>
                    </div>
                    <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" size="small"><img
                        :src="require('../../../assets/img/cai3.png')" alt="" />
                      <p>删除</p>
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="print_box">
          <div class="print_txt">点餐是否受库存影响</div>
          <el-radio v-model="print_serch" label="1"  @change="update_ent_sys_set">是</el-radio>
          <el-radio v-model="print_serch" label="0"  @change="update_ent_sys_set">否</el-radio>
        </div>

        <!-- 打印选择 -->
        <!-- <div class="print_box" v-if="false">
          <div class="print_txt">是否使用打印功能</div>
          <el-radio v-model="print_serch" label="1">是</el-radio>
          <el-radio v-model="print_serch" label="2">否</el-radio>
          <div v-if="print_serch=='1'">
            <el-input class="print_inp" v-model="print_input" type="number" placeholder="请输入打印数量"></el-input>
            <el-button type="danger" >确定</el-button>
          </div>

        </div> -->
      </div>

      <!-- 新增菜品 -->
      <div class="variety" v-if="showShade">
        <div class="variety-title">
          <p>{{ BianJi_CaiPin_PanDuan == '0' ? '新增菜品' : '编辑菜品' }}</p>
          <span @click="close">X</span>
        </div>
        <div class="variety-titleS">
          <span></span>
          <p>菜品信息</p>
        </div>
        <!--  :rules="rules" -->
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="菜品名称" :required="true">
            <el-input type="text" placeholder="请输入内容" v-model="form.text" maxlength="10" show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="菜品编号" :required="true">
            <el-input type="text" placeholder="请输入内容" v-model="form.serialCai">
            </el-input>
          </el-form-item>
          <el-form-item label="所属仓库" :required="true">
            <!-- :placeholder='form.newcangkumingA' -->
            <!-- {{form.newcangkumingA}} -->
            <!-- <el-cascader v-model="form.newcangkumingA" :options="StorageRoom" :props="{
                multiple: true,
                value: 'site_id',
                label: 'site_name',
                children: 'list',
                checkStrictly: true
              }" clearable @change="handleToCangKu"  :placeholder='newcangkumingA'>
            </el-cascader> -->
            <el-select v-model="form.newcangkumingA" placeholder="请选择"  @change="handleToCangKu" >
              <el-option
                v-for="item in StorageRoom"
                :key="item.site_id"
                :label="item.site_name"
                :value="item.site_id">
              </el-option>
            </el-select>

          </el-form-item>
          <el-form-item label="所属菜系" :required="true">
            <!-- multiple 多选  clearable-->
            <el-select v-model="form.KuFAngvalue" placeholder="请选择菜系" @change="fenlei_Data">
              <el-option v-for="item in classification" :key="item.cat_id" :label="item.cat_name" :value="item.cat_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="批次号" :required="true">
            <el-input type="text" placeholder="请输入内容" v-model="form.BatchNumber">
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="会员价" :required="true">
            <el-input type="text" placeholder="请输入内容" v-model="form.priceMember">
            </el-input>
          </el-form-item> -->
          <el-form-item label="有效天数" :required="true">
            <el-input type="text" placeholder="请输入内容" v-model="form.TimeValue">
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="有效期至" :required='true'>
            <el-date-picker v-model="form.TimeValue" type="date" placeholder="选择日期" :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item> -->
          <el-form-item label="菜品单位" :required="true">
            <el-input type="text" placeholder="请输入内容" v-model="form.units">
            </el-input>
          </el-form-item>

          <el-form-item label="成本卡" :required="true">
            <el-select v-model="form.flag_cost" placeholder="请选择是否有成本卡">
              <el-option v-for="item in chengben" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 选项卡 -->
          <el-tabs class="tabS" v-model="spec_listValue" type="card" editable @edit="handleTabsEdit">
            <el-tab-pane :key="item.name" v-for="(item, index) in spec_list" :label="item.titleSpec" :name="item.name">
              <!-- {{item}}{{index}} -->
              <!-- {{item.content}}{{index}} -->
              <el-form ref="form_tabs" :model="spec_list[index]" label-width="80px">
                <el-form-item label="规格名称">
                  <el-input type="text" placeholder="请输入内容" v-model="spec_list[index].spec_title">
                  </el-input>
                </el-form-item>

                <el-form-item label="菜品规格">
                  <el-input type="text" placeholder="请输入内容" v-model="spec_list[index].prod_spec">
                    <template slot="append">g</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="统一价格">
                  <el-input type="text" placeholder="请输入内容" v-model="spec_list[index].price_uniform"
                    :disabled="spec_list[index].price_conflictA">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="会员价">
                  <el-input type="text" placeholder="请输入会员价" v-model="spec_list[index].price_member">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="早市价格">
                  <el-input type="text" placeholder="请输入会员价" v-model="spec_list[index].price_morning"
                    :disabled="spec_list[index].price_conflictB">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="午市价格">
                  <el-input type="text" placeholder="请输入会员价" v-model="spec_list[index].price_noon"
                    :disabled="spec_list[index].price_conflictB">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="晚市价格">
                  <el-input type="text" placeholder="请输入会员价" v-model="spec_list[index].price_night"
                    :disabled="spec_list[index].price_conflictB">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="外卖价格">
                  <el-input type="text" placeholder="请输入价格" v-model="spec_list[index].price_takeout"
                    :disabled="spec_list[index].price_conflictB">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-form>
              <div style="width: 100%;height: 36px;">
                饭市价格应与门店营业时间相对应，如门店为24小时营业，则菜品价格应设置为统一价格
              </div>
            </el-tab-pane>
          </el-tabs>

          <div style="width:100%">
            <!-- 图片 -->
            <div class="thumbnail">
              <p>缩略图</p>
              <span class="jilu">({{ ZengJiaImageLen }}/4)菜品添加</span>
            </div>
            <!-- action="http://39.100.63.95:8080/cbreat/V1.0.0" -->
            <!-- auto-upload	是否在选取文件后立即进行上传 -->
            <!-- list-type	文件列表的类型 -->
            <!-- on-success	文件上传成功时的钩子 -->
            <!-- on-error	文件上传失败时的钩子 -->
            <!-- on-remove	文件列表移除文件时的钩子 -->
            <!-- limit	最大允许上传个数 -->
            <!-- http-request	覆盖默认的上传行为，可以自定义上传的实现 -->
            <!-- before-upload	上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。 -->
            <!-- on-change	文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用 -->
            <el-upload :auto-upload="false" action="#" ref="editCat" list-type="picture-card" :limit="4"
              :on-remove="handleRemove" :on-change="TianJiaFood_Pic" :file-list="BianJi_Img">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <el-button v-if="BianJi_CaiPin_PanDuan == '0'" :loading="submitLoading" type="danger" @click="addNewCaiPin('form')">确定</el-button>
          <el-button v-else type="danger" @click="BaocunXiuGaiCaiPin">保存</el-button>
        </el-form>
      </div>
      <!-- 新增套餐 - -- -->
      <div class="variety-cost" v-if="cost_get">
        <div class="variety-cost-title">
          <p>{{ BianJi_TaoCan_PanDuan == '0' ? '新增套餐' : '编辑套餐' }}</p>
          <span @click="close_cost">X</span>
        </div>
        <div>
          <!-- 666666666 -->
          <el-form ref="form" :model="form_cost" label-width="80px">
            <el-form-item label="菜品名称" :required="true">
              <el-input type="text" placeholder="请输入内容" v-model="form_cost.text" maxlength="10" show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item label="菜品编号" :required="true">
              <el-input type="text" placeholder="请输入内容" v-model="form_cost.serial">
              </el-input>
            </el-form-item>
            <el-form-item label="所属菜系" :required="true">
              <el-select v-model="form_cost.value" @change="SetMeal_add_fenlei" placeholder="请选择分类">
                <el-option v-for="item in classify" :key="item.cat_id" :label="item.cat_name" :value="item.cat_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="菜品规格" :required="true">
              <el-input type="text" placeholder="请输入内容" v-model="form_cost.speci">
              </el-input>
            </el-form-item>
            <el-form-item label="菜品单位" :required="true">
              <el-input type="text" placeholder="请输入内容" v-model="form_cost.units">
              </el-input>
            </el-form-item>
            <!-- <el-select v-model="form_cost.Choose" multiple @change="SetMeal_add_CaiPin" placeholder="请选择">
                  <el-option
                    v-for="item in Choose"
                    :key="item.prod_id"
                    :label="item.cat_name"
                    :value="item.prod_id">
                  </el-option>
                </el-select> -->
            <!-- 选择菜品 -->
            <el-form-item label="选择菜品" style="width: 75%" :required="true">
              <el-table :data="tableDataA" border height="200" style="width: 100%">
                <el-table-column prop="prod_id" label="名称" width="300">
                  <template slot-scope="scope">

                    <el-cascader v-model="scope.row.prod_id" :props="{
                        emitPath: false,
                        value: 'prod_id',
                        label: 'prod_name',
                        children: 'prod_children',
                        disabled: 'isuse'
                      }" :options="Cuisines_tree" @change="handleChange"></el-cascader>

                  </template>
                </el-table-column>
                <el-table-column prop="qty" label="份" width="160">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.qty" placeholder="请输入规格"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                  <template slot-scope="scope">
                    <el-button type="danger" icon="el-icon-delete" circle
                      @click="handleDel_TaoCanCaiPin(scope.$index, scope.row)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div>
                <el-button class="increase-bu" @click="TAOCANaddLine()">+ 增加一行</el-button>
              </div>
            </el-form-item>

            <el-form-item label="统一价格">
              <el-input type="text" placeholder="请输入内容" v-model="form_cost.price" :disabled="TaoCanJiaGechongtuA">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="会员价">
              <!-- 777777777777 -->
              <el-input type="text" placeholder="请输入会员价" v-model="form_cost.adjust">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="早市价格">
              <el-input type="text" placeholder="请输入会员价" v-model="form_cost.morning" :disabled="TaoCanJiaGechongtu">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="午市价格">
              <el-input type="text" placeholder="请输入会员价" v-model="form_cost.afternoon" :disabled="TaoCanJiaGechongtu">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="晚市价格">
              <el-input type="text" placeholder="请输入会员价" v-model="form_cost.night" :disabled="TaoCanJiaGechongtu">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="外卖价格">
              <el-input type="text" placeholder="请输入价格" v-model="form_cost.price_takeout"
                :disabled="TaoCanJiaGechongtu">
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-form>
          <div style="width:100%">
            <!-- 图片 -->
            <div class="thumbnail">
              <p>缩略图</p>
              <span class="jilu">({{ imageLen }}/4)</span>
            </div>
            <!-- on-success 菜品	文件上传成功时的钩子 -->
            <!-- auto-upload（是否立即上传图片）需要设置成false -->
            <el-upload :auto-upload="false" action="#" ref="bianjitaocan" list-type="picture-card"
              :on-success="handleSuccess" :limit="4" :file-list="TaoCan_BianJi_TuPian" :on-remove="handleRemove"
              :on-change="TianJiaTaoCan">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <el-button type="danger" @click="SetMeal_Confirm">确定</el-button>
        </div>
      </div>
      <!-- 成本卡 -->
      <div class="Xcard" v-if="Xcard">
        <div class="variety-cost-title">
          <p>成本卡</p>
          <span @click="Xcard_cost">X</span>
        </div>
        <div>
          <el-form ref="form" :model="form_vegetable" label-width="80px" :rules="cardrules">
            <div class="costCard-foodname">
              <el-form-item label="菜品名称" :required="true">
                <el-input type="text" placeholder="请输入内容" v-model="form_vegetable.selling_dish_name">
                </el-input>
              </el-form-item>
              <el-form-item label="菜品分类" :required="true">
                <el-select v-model="form_vegetable.value" @change="costCard_Types_of_Courses" placeholder="请选择">
                  <el-option v-for="item in greens" :key="item.cat_id" :label="item.cat_name" :value="item.cat_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="costCard-foodBuZhou">
              <div class="foodBuZhou-left">
                <el-form-item label="烹饪时间" :required="true">
                  <el-input type="text" placeholder="请输入内容" v-model="form_vegetable.selling_time">
                    <template slot="append">min</template>
                  </el-input>
                </el-form-item>

                <div class="ingredient">
                  <span>所需食材</span>
                  <div>
                    <el-table :data="tableDataS" border height="200" style="width: 100%">
                      <el-table-column prop="mater_name" label="名称" width="150">
                        <template slot-scope="scope">
                          <!-- <el-input v-model="scope.row.mater_name" placeholder="请输入名称"></el-input> scope.$index, scope.row -->
                          <el-select v-model="scope.row.mater_name" filterable placeholder="请选择" @change="
                              onChangeStripe(scope.$index, scope.row.mater_name)
                            ">
                            <el-option v-for="item in tableDataSS" :disabled="item.mater_disabled" :key="item.prod_id"
                              :label="item.prod_name" :value="item.prod_id">
                            </el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column prop="mater_spec" label="规格(g)" width="120">
                        <template slot-scope="scope">
                          <el-input v-model="scope.row.mater_spec" placeholder="请输入规格"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" width="150">
                        <template slot-scope="scope">
                          <!-- <el-input v-model="scope.row.mater_spec" placeholder="请输入规格"></el-input> -->
                          <el-button type="danger" icon="el-icon-delete" circle @click="
                              handleDeletecard_YuanLiao(scope.$index, scope.row)
                            "></el-button>
                        </template>
                        <!--@click="handleDelete(scope.$index, scope.row)" -->
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
              <!-- 制作步骤 -->
              <div class="foodBuZhou-right">
                <el-form-item label="制作步骤" :required="true">
                  <el-input type="textarea" :autosize="{ minRows: 12, maxRows: 24 }" placeholder="请输入内容"
                    v-model="form_vegetable.textarea">
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <el-button class="increase-bu" @click="addLine()">+ 增加一行</el-button>
            <!-- 5555555555 -->
            <el-form-item label="制造费用" :required="true">
              <el-input type="text" placeholder="请输入内容" v-model="form_vegetable.expense">
              </el-input>
            </el-form-item>
            <div class="costCard-xiafang">
              <div class="CBR-kuandu">
                <el-form-item label="售价" :required="true">
                  <el-input type="text" placeholder="请输入内容" v-model="form_vegetable.selling_selling_price">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="成本" :required="true">
                  <el-input type="text" placeholder="请输入内容" v-model="form_vegetable.cost">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="CBR-kuandu">
                <el-form-item label="毛利" :required="true">
                  <el-input type="text" placeholder="请输入内容" v-model="form_vegetable.gross">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="毛利率" :required="true">
                  <el-input type="text" placeholder="请输入内容" v-model="form_vegetable.profit">
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <div>
              <!-- 图片 -->
              <div class="thumbnail">
                <p>缩略图</p>
                <span class="jilu">({{ ZengJiaImageLen }}/4)菜品添加</span>
              </div>
              <!-- action="http://39.100.63.95:8080/cbreat/V1.0.0" -->
              <!-- auto-upload	是否在选取文件后立即进行上传 -->
              <!-- list-type	文件列表的类型 -->
              <!-- on-success	文件上传成功时的钩子 -->
              <!-- on-error	文件上传失败时的钩子 -->
              <!-- on-remove	文件列表移除文件时的钩子 -->
              <!-- limit	最大允许上传个数 -->
              <!-- http-request	覆盖默认的上传行为，可以自定义上传的实现 -->
              <!-- before-upload	上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。 -->
              <!-- on-change	文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用 -->
              <el-upload :auto-upload="false" action="#" ref="chengbenKa" list-type="picture-card" :limit="4"
                :on-remove="handleRemove" :on-change="CostCard_Pic" :file-list="CostCard_Img">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </el-form>
        </div>

        <el-button class="Xcard_button" type="danger" v-if="card_updata" @click="costCard_updata">更新</el-button>
        <el-button class="Xcard_button" type="danger" v-else @click="costCard_tianjia">确定</el-button>
      </div>

      <div class="shade" @click="giabain()" v-if="shade"></div>

      <!-- 导入   -->
      <el-dialog title="导入菜品信息" :visible.sync="import_cat_file_show" width="60%" v-if="import_cat_file_show">
        <!-- :before-close="handleClose" -->
        <!-- <p>点击 <span style="color:#FC4353" @click="MuMan_XiaZai">下载导入模板文件</span> </p> -->
        <import-file ref="submit" piliangdaoru="菜品"></import-file>
        <span slot="footer" class="dialog-footer">
          <el-button @click="import_cat_file_show = false">取 消</el-button>
          <el-button type="primary" @click="import_cat_file_up">确定上传</el-button>
        </span>
      </el-dialog>
      <!-- 导出 -->
      <el-dialog title="导出菜品信息" :visible.sync="export_cat_file_show" width="60%" v-if="export_cat_file_show">
        <!-- :before-close="handleClose" -->
        <!-- <p>点击 <span style="color:#FC4353" @click="MuMan_XiaZai">下载导入模板文件</span> </p> -->
        <export-file ref="downFile" leixing="菜品"></export-file>
        <span slot="footer" class="dialog-footer">
          <el-button @click="export_cat_file_show = false">取 消</el-button>
          <el-button type="primary" @click="export_cat_file_down">导出</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  let ent_id = ''
  // /insert_category_info // 获取产品列表 菜品列表   // /get_prod_list  // 获取产品列表
  // /insert_prod_info  // 新增产品  新增菜品  // /query_category_info_list // 查询产品种类列表  // query_store_house_list  // 查询仓库列表/
  // /delete_prod_info // 删除产品--批量删除 // /update_prod_info // 修改产品 参数同insert_prod_info // /insert_cost_info // 新增成本卡
  // /query_cost_info_detail // 查询成本卡明细  // /get_prod_list_like // 获取产品列表 --模糊查询
  // /query_combo_prod_list // 查询套餐的产品列表
  // /query_cost_info_detail // 查询成本卡明细  // /get_prod_list_like // 获取产品列表 --模糊查询
  // /query_combo_cost_list // 查询套餐的产品列表 //仓库树：query_site_tree      查询产品列表/query_prod_info_list
  // query_prod_info_detail 菜品明细   // update_cost_info-------更新成本卡
  import {
    insert_prod_info,
    query_category_info_list,
    query_store_house_list,
    query_prod_info_list,
    delete_prod_info,
    import_customer_ai,
    update_prod_info,
    insert_cost_info,
    query_cost_info_detail,
    get_prod_list_like,
    query_combo_prod_list,
    query_combo_cost_list,
    query_site_tree,
    query_prod_info_detail,
    query_prod_tree_of_1cat_2prod,
    update_cost_info,
  } from '@api/foodqiye'

  import {
    query_shop_info_list,
    replace_ent_img,
    query_ent_img_list,
    insert_member_rule,
    query_member_rule,
    query_cat_prod_list_of_tree,

    update_ent_sys_set,
    query_ent_sys_set,
  } from '../../../api/store'

  import {
    query_category_tree
  } from '../../../api/public'
  // import { showLoading, hideLoading } from './loading'
  import {
    mapActions
  } from 'vuex'
  import importFile from "@c/modules/daorudaochu/Imports"
  import exportFile from "@c/modules/daorudaochu/Exportb"
  export default {
    data() {
      return {

        print_serch:'', // 是否搜库存限制
        print_serch_huyuan:'', // 会员价
        print_serch_zww:'', // 早午晚

        print_input:'', // 数量

        chengben: [{
          value: '1',
          label: '是'
        }, {
          value: '0',
          label: '否'
        }],


        export_cat_file_show: false,
        import_cat_file_show: false,
        card_updata: true,
        // -----------------
        spec_listValue: '1',
        spec_list: [{
          titleSpec: '方案1',
          name: '1',
          // content: 'Tab 1 content',
          spec_title: '', //规格名称
          price_uniform: '', // 统一价格
          prod_spec: '', // 菜品规格
          price_member: '', // 会员价
          price_morning: '', // 早市价格
          price_noon: '', // 午市价格
          price_night: '', // 晚市价格
          price_takeout: '', // 外卖价格
          price_conflictA: false, // 价格冲突A
          price_conflictB: false, // 价格冲突B
        }],
        tabIndex: 1,
        jiaGeChongTu_cai_t: false, // 价格冲突 早午晚市的价格 统一价格
        jiaGeChongTu_cai: false, // 价格冲突 统一价格 早午晚市的价格
        bianjicaipin_prod_id: '', // 菜品的id
        // ---------------
        propsSC: {},
        // Choose1: [],
        // 判断是不是菜品的编辑  0 新增 1 编辑
        BianJi_CaiPin_PanDuan: '0',
        // 判断是不是套餐的编辑  0 新增 1 编辑
        BianJi_TaoCan_PanDuan: '0',
        // 上传文件 的列表
        fileList: [],
        fileList_Arr: [], // 存放文件数组
        // 在添加统一价格后

        // 添加菜品
        showShade: false,
        jiagechongtu: false, // 价格冲突 早午晚市的价格 统一价格
        jiagechongtuA: false, // 价格冲突 统一价格 早午晚市的价格
        //  新增套餐           酒水成本卡
        cost_get: false,
        // 菜品成本卡
        Xcard: false,
        // 遮罩层
        shade: false,
        // options: [{
        //     value: '选项1',
        //     label: '黄金糕'
        //   },
        //   {
        //     value: '选项2',
        //     label: '双皮奶'
        //   }
        // ],
        // 套餐的数据信息
        SetMeal_classification: '',

        branch: [],
        // 时间选择
        nameA: '',
        time: '',

        tableData: [],
        role: false,
        multipleSelection: [],
        name: '',
        post: '',
        phone: '',
        mailbox: '',
        // 新增菜品
        form: {
          text: '', // 菜品名称
          serialCai: '', // 菜品编号
          adjust: '', // 会员价
          // Choose1: [], // 所属仓库
          BatchNumber: '', // 批次号
          TimeValue: '', // 有效的天数 有效的时间日期
          KuFAngvalue: '', // 所属菜系的选择  id
          price_takeout: '', // 外卖价格
          newcangkumingA: '', // 选择仓库 ok
          flag_cost: ''
        },
        // 新仓库id
        newcangkuid: [],
        // 新仓库名
        newcangkuming: [],
        newcangkumingA: '', // 选择仓库 ok del
        // 新增菜品的分类框的下拉框
        classification: [],
        // 存放-新增菜品-选择仓库的信息列表
        StorageRoom: [],
        // 存放加密后的图片
        BaseImg: [],
        // 图片后缀
        BaseImg_HouZhui: [],
        // 库房的名字
        kufang_name: [], // ''
        // 分类的名字 存放分类的名称
        KuFAngvalue_name: '',
        // 分类id的字符串
        // fenlei_id:'',
        // 禁止选择的日期
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          },
        },
        arr3: [],
        props: {
          multiple: true,
          value: 'site_id',
          label: 'site_name',
          children: 'list'
        }, // 对于新增菜品的仓库选择的级联选择器
        // 查看图片的数量
        ZengJiaImageLen: 0,
        // ========= 新增套餐   新增分类
        prod_id_taocan: '', // 存放菜品/套餐的id
        form_cost: {
          text: '', // 菜品名称
          serial: '', // 菜品编号
          speci: '', // 菜品规格
          units: '', // 菜品单位
          price: '', // 统一价格
          adjust: '', // 会员价
          morning: '', // 早市价格
          afternoon: '', // 午市价格
          night: '', // 晚市价格
          // Choose: '', // 选择菜品
          price_takeout: '', // 选择外卖价格
          value: '' // 所属菜系
        },
        // 套餐的分类选项
        classify: [{
          value: '选项1',
          label: '热菜'
        }, {
          value: '选项2',
          label: '凉菜'
        }],
        // 选中套餐分类的id号
        SetMeal_classification: '',
        // 套餐的图片后缀
        TaoCan_Base_HouZhui: [],
        // 存放加密后的图片数据
        TaoCan_Img: [],
        // // 套餐 选中的分类信息
        TaoCan_xinxi: {},
        // 套餐菜品的选择
        TaoCanCaiPin: [],
        // 套餐价格冲突
        TaoCanJiaGechongtu: false, // 价格冲突 早午晚市的价格 统一价格
        TaoCanJiaGechongtuA: false, // 价格冲突 统一价格 早午晚市的价格
        // Choose: [], // 编辑套菜选择的菜品
        // 选中的菜品信息
        // TianJiaTaoCan:'',
        // 添加菜品的名称
        TJ_TaoCan_name: '',
        // 添加菜品的id
        TJ_TaoCan_id: '',
        //
        // 编辑图里的 修改 图片的设置
        BianJi_Img: [],
        // 套餐编辑图片的
        TaoCan_BianJi_TuPian: [],
        imageLen: '0', // 图片数量
        // ========成本卡
        form_vegetable: {
          selling_dish_name: '', // 菜品名称
          value: '', // 菜品分类
          selling_time: '', // 烹饪时间
          textarea: '', // 制作步骤
          expense: '', // 制造费用
          selling_selling_price: '', // 售价
          cost: '', // 成本
          gross: '', // 毛利
          profit: '' // 毛利率
        },
        // 菜成本卡  //成本卡的 菜品分类
        greens: [],
        // 存放菜品的分类id
        costCard_cat_id: '',
        // 存放成本卡选中的区域
        costCard_food: {},
        // 存放显示图片的数组
        CostCard_Img: [],
        //成本卡的后缀
        CostCard_HouZhui: [],
        // 转化Base64后的图片
        CostCard_Image: [],
        valueS: '',
        dialogImageUrl: '',
        dialogVisible: false,
        tableDataS: [],
        tableDataSS: [],
        card_item: false, // 判断是否是填完的
        // 套餐菜品管理
        tableDataA: [],
        // tableDataAS: [],

        TuPianUrl: '',
        url: 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0',
        BianJi_PanDuan_XinXi: '', // 判断添加是菜品还是套餐
        allInfo: [],
        // // 成本卡的非空
        cardrules: {},
        // 拿到菜系的分类树
        Cuisines_tree: '',
        // 修改拿到后菜系的分类树
        xiu_Cuisines_tree: '',

        submitLoading: false
      }
    },
    components: {
      importFile,
      exportFile
    },



    created() {
      //获取菜树
      this.get_cat_list()

      console.log(this.$store)


    },


    watch: {

      // spec_list: {
      //   handler (newval, oldval) {
      //     // console.log('监听',newval)
      //     // console.log(this.spec_list);
      //     for (let itemA of newval) {
      //       if (itemA.price_uniform != '') {
      //         itemA.price_conflictB = true
      //       } else {
      //         itemA.price_conflictB = false
      //       }
      //       if (itemA.price_morning != '' || itemA.price_noon != '' || itemA.price_night != '' || itemA.price_takeout != '') {
      //         itemA.price_conflictA = true
      //       } else {
      //         itemA.price_conflictA = false
      //       }
      //     }
      //   },
      //   deep: true
      // },
      // 监听套餐的价格冲突
      // 'form_cost.price': {
      //   handler (newval, oldval) {
      //     if (newval.length > 0) {
      //       this.TaoCanJiaGechongtu = true
      //     } else {
      //       this.TaoCanJiaGechongtu = false
      //     }
      //   }
      // },
      // form_cost: {
      //   handler (newval, oldval) {
      //     // console.log('监听',newval);
      //     if (newval.morning != '' || newval.afternoon != '' || newval.night != '' || newval.price_takeout != '') {
      //       this.TaoCanJiaGechongtuA = true
      //     } else {
      //       this.TaoCanJiaGechongtuA = false
      //     }
      //   },
      //   deep: true
      // }



    },
    mounted() {
      this.Init()
      this.query_ent_sys_set()
    },
    computed: {
      cat_list() {
        console.log(this.$store.state.cat_list, '拿到的值');
        return this.$store.state.cat_list
      }
    },
    methods: {

query_ent_sys_set(){
  query_ent_sys_set({data:{
    ent_id:this.$store.state.ent_id
  }}).then((res)=>{
    console.log('ssss',res);
    if (res.code == '200') {
      // flag_Stock_limit 点餐是否开启库存限制  // flag_market_price 早午晚市是否启用   //flag_member_price 会员价是否启用
      this.print_serch = res.body.data.flag_stock_limit
      this.print_serch_huyuan = res.body.data.flag_member_price
      this.print_serch_zww = res.body.data.flag_market_price

    }
  })
},

update_ent_sys_set(){
  console.log('=====');
  update_ent_sys_set({data:{
    ent_id:this.$store.state.ent_id,
    flag_stock_limit:this.print_serch
  }}).then(((res)=>{
    console.log(res)
    if (res.code == '200') {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
    }
  }))
},

update_ent_sys_set_huiyuan(){
  console.log('=====');
  update_ent_sys_set({data:{
    ent_id:this.$store.state.ent_id,
    flag_member_limit:this.print_serch_huyuan
  }}).then(((res)=>{
    console.log(res)
    if (res.code == '200') {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
    }
  }))
},

update_ent_sys_set_zww(){
  console.log('=====');
  update_ent_sys_set({data:{
    ent_id:this.$store.state.ent_id,
    flag_market_limit:this.print_serch_zww
  }}).then(((res)=>{
    console.log(res)
    if (res.code == '200') {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
    }
  }))
},


      Init() {
        this.JiChuXinXi()
        this.obtain_CaiPin()
        this.CardCaipin()
        this.Cuisines_tree = this.cat_list

        for (const i in this.Cuisines_tree) {
          this.Cuisines_tree[i].prod_name = this.Cuisines_tree[i].cat_name
          this.Cuisines_tree[i].prod_id = this.Cuisines_tree[i].prod_id
          if (this.Cuisines_tree[i].prod_children) {
            for (const j in this.Cuisines_tree[i].prod_children) {
              this.Cuisines_tree[i].prod_children[j].prod_id = this.Cuisines_tree[i].prod_children[j].prod_id
              this.Cuisines_tree[i].prod_children[j].prod_name = this.Cuisines_tree[i].prod_children[j].prod_name
              this.Cuisines_tree[i].prod_children[j].isuse = false
              if (this.Cuisines_tree[i].prod_children[j].spec_list && this.Cuisines_tree[i].prod_children[j].spec_list
                .length) {
                for (const n in this.Cuisines_tree[i].prod_children[j].spec_list) {
                  this.Cuisines_tree[i].prod_children[j].spec_list[n].prod_id = this.Cuisines_tree[i].prod_children[j]
                    .spec_list[n].spec_id
                  this.Cuisines_tree[i].prod_children[j].spec_list[n].prod_name = this.Cuisines_tree[i].prod_children[j]
                    .spec_list[n].spec_title
                  this.Cuisines_tree[i].prod_children[j].spec_list[n].isuse = false
                }
                this.Cuisines_tree[i].prod_children[j].prod_children = this.Cuisines_tree[i].prod_children[j].spec_list
              }


            }

          }



        }
        // 树形 图

        // for (const i in this.Cuisines_tree) {
        //   for (let k in this.Cuisines_tree[i].prod_children) {
        //     this.Cuisines_tree[i].prod_children[k].cat_id = this.Cuisines_tree[i].prod_children[k].prod_id
        //     this.Cuisines_tree[i].prod_children[k].cat_name = this.Cuisines_tree[i].prod_children[k].cat_name
        //     this.Cuisines_tree[i].prod_children[k].isuse = false

        //     // itemchildren.cat_name = itemchildren.prod_name
        //     // itemchildren.isuse=false
        //   }
        // }
        // for (let item of this.Cuisines_tree) {
        //   for (let itemchildren of item.prod_children) {
        //     itemchildren.cat_id = itemchildren.prod_id
        //     itemchildren.cat_name = itemchildren.prod_name
        //     itemchildren.isuse = false

        //       if(itemchildren.spec_list&&itemchildren.spec_list.length){

        //           console.log('12312311112',itemchildren.spec_list)

        //         for (let itemchildren1 of item.prod_children.spec_list) {
        //               itemchildren1.cat_id = itemchildren1.spec_id
        //               itemchildren1.cat_name = itemchildren1.spec_title
        //               itemchildren1.isuse = false

        //         }

        //         itemchildren.prod_children=itemchildren.spec_list

        //       }

        //   }
        // }




        console.log('this.xiu_Cuisines_tree', this.Cuisines_tree)
      },
      handleChange(value) {
        console.log('选择菜品', value);
        for (const i in this.Cuisines_tree) {
          for (let k in this.Cuisines_tree[i].prod_children) {
            if (this.Cuisines_tree[i].prod_children[k].prod_id == value) {
              this.Cuisines_tree[i].prod_children[k].isuse = true
            }
            if (this.Cuisines_tree[i].prod_children[k].spec_list && this.Cuisines_tree[i].prod_children[k].spec_list
              .length) {
              for (const n in this.Cuisines_tree[i].prod_children[k].spec_list) {
                if (this.Cuisines_tree[i].prod_children[k].spec_list[n].prod_id == value)
                  this.Cuisines_tree[i].prod_children[k].spec_list[n].isuse = true
              }
            }
          }
        }
      },
      test(val) {
        //
      },
      // ------------------------------------
      handleTabsEdit(targetName, action) {
        if (action === 'add') {
          let newTabName = ++this.tabIndex + '';
          this.spec_list.push({
            titleSpec: '方案' + newTabName,
            name: newTabName,
            // content: 'New Tab content',
            spec_title: '', //规格名称
            price_uniform: '', // 统一价格
            prod_spec: '', // 菜品规格
            price_member: '', // 会员价
            price_morning: '', // 早市价格
            price_noon: '', // 午市价格
            price_night: '', // 晚市价格
            price_takeout: '', // 外卖价格
            price_conflictA: false, // 价格冲突A
            price_conflictB: false, // 价格冲突B
          });
          this.spec_listValue = newTabName;
        }
        if (action === 'remove') {
          let tabs = this.spec_list;
          let activeName = this.spec_listValue;
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                let nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                }
              }
            });
          }
          this.spec_listValue = activeName;
          this.spec_list = tabs.filter(tab => tab.name !== targetName);
        }
      },
      // ----------------------------------------------------


      // 成本卡删除
      handleDeletecard_YuanLiao(index, row) {
        console.log(index, row, this.tableDataS);

        // this.tableDataSS
        for (let index in this.tableDataSS) {
          if (row.mater_id == this.tableDataSS[index].prod_id) {
            this.tableDataSS[index].mater_disabled = false
          }
        }

        this.tableDataS.splice(index, 1)

      },
      // 删除 套餐菜品
      handleDel_TaoCanCaiPin(index, row) {
        console.log(row)
        let deletePoid = row.prod_id
        for (const i in this.Cuisines_tree) {
          for (let k in this.Cuisines_tree[i].prod_children) {
            if (deletePoid == this.Cuisines_tree[i].prod_children[k].prod_id) {

              this.Cuisines_tree[i].prod_children[k].isuse = false
            }
            if (this.Cuisines_tree[i].prod_children[k].spec_list && this.Cuisines_tree[i].prod_children[k].spec_list
              .length) {
              for (const n in this.Cuisines_tree[i].prod_children[k].spec_list) {
                if (this.Cuisines_tree[i].prod_children[k].spec_list[n].prod_id == deletePoid)
                  this.Cuisines_tree[i].prod_children[k].spec_list[n].isuse = false
              }
            }
          }
        }
        this.tableDataA.splice(index, 1)


      },
      xuanzecaipin(value) {
        console.log(value);

      },
      disabledDate(time) {
        return time.getTime() > Date.now()
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          this.$refs.multipleTable.clearSelection()
        }
      },
      // 搜索
      searchAllCompany() {
        console.log('测试一下搜索', this.nameA, 'this.product');
        // get_prod_list_like({query_prod_info_list
        query_prod_info_list({
          data: {
            ent_id: this.$store.state.userInfo.ent_info.ent_id,
            // prod_name: this.nameA,
            search_str: this.nameA,
            active: '1',
          }
        }).then((res) => {
          this.tableData = res.body.data
          console.log(this.tableData);
        })
      },
      // 页面初始进入 查询分类 // 查询产品种类列表
      JiChuXinXi() {
        let self = this
        // 查询分类
        query_category_info_list({
          data: {
            // ent_id:self.$store.state.ent_id,
            // ent_id: this.$store.state.userInfo.ent_info.ent_id,
            ent_id: this.$store.state.ent_id,
            active: '1'
          }
        }).then((res) => {
          console.log('查询的信息---查询分类', res);
          // 菜品的分类

          // 新增菜品的分类框的下拉框
          this.classification = res.body.data
          // 套餐的分类数据
          this.classify = res.body.data
          // 成本卡的分类
          this.greens = res.body.data
        })
        query_site_tree({
          data: {
            ent_id: this.$store.state.ent_id,
          }
        }).then((res) => {
          // console.log(res,'555555555555555');
          //
          this.StorageRoom = res.body.data
          this.getChilder(this.StorageRoom)

          // console.log(this.allInfo)

          // console.log(this.allInfo['CK1616602136913xuxj'])

          // console.log(this.allInfo['CK1616602136913xuxj'].site_name)
        })
      },

      // 获取菜品列表
      obtain_CaiPin() {
        let self = this
        query_prod_info_list({
          data: {
            // ent_id:self.$store.state.ent_id,
            ent_id: this.$store.state.ent_id,
            // cat_id:'',
            active: '1',
            // site_no:''
          }
        }).then((res) => {
          console.log('======菜品数据---获取菜品列表', res);

          let date = res.body.data
          this.tableData = date
          
        })
      },

      // 成本卡的菜品 原材料
      CardCaipin() {
        let self = this
        query_prod_info_list({
          data: {
            ent_id: this.$store.state.ent_id,
            active: '1',
            prod_type: '3' // 获取菜品的原材料
          }
        }).then((res) => {
          console.log('菜品数据---获取菜品的原材料', res);
          // this.tableDataSS =  // 没有规格 价格的菜品
          let nowAddtableDataSS = res.body.data
          for (let item of nowAddtableDataSS) {
            item.mater_disabled = false // mater_disabled // 禁用选项
          }
          this.tableDataSS = nowAddtableDataSS
        })
      },
      // 获取分类 id
      fenlei_Data(fenlei) {
        // console.log(this.form.KuFAngvalue,'aaaaaa',fenlei);
        this.classification.forEach((itema) => {
          if (itema.cat_id == fenlei) {
            this.KuFAngvalue_name = itema.cat_name
          }
        })
        console.log('aaaaaa', this.KuFAngvalue_name);
      },
      // 遮罩
      giabain() {
        this.showShade = false
        this.shade = false
        this.cost_get = false
        this.Xcard = false
      },
      // ------新增菜品------
      // 显示新增菜品 新增菜品按钮
      roleS() {
        this.BianJi_CaiPin_PanDuan = '0' // 新增菜品 0 编辑菜品 1
        this.showShade = true
        this.shade = true,
          this.BaseImg = [] // 清空默认图片
        this.QingKongCaiPinDAn(),
          this.huanyuan_spec_list()
        this.spec_listValue = '1' // 可以del 20210426:1103
        this.tabIndex = 1
        this.Init()

      },

      // 关闭新增菜品
      close() {
        this.showShade = false
        this.shade = false
        this.QingKongCaiPinDAn() // 清空菜品信息
        this.huanyuan_spec_list() // 菜品规格
      },
      // 显示新增套餐  重新获取菜的列表树
      card() {
        // this.this.$store.state.cat_list
        this.get_cat_list().then(res => {
          if (res) {
            this.Cuisines_tree = res
            for (const i in this.Cuisines_tree) {
              this.Cuisines_tree[i].prod_name = this.Cuisines_tree[i].cat_name
              this.Cuisines_tree[i].prod_id = this.Cuisines_tree[i].prod_id
            }
            this.BianJi_TaoCan_PanDuan = '0'
            this.cost_get = true
            this.shade = true
            this.QingKongTaoCan()
            this.Init()
          }
        });

        // 套餐菜品 的新增
        // this.Cuisines_tree = this.cat_list

      },
      // 关闭套餐
      close_cost() {
        this.cost_get = false
        this.shade = false
        this.showShade = false
        console.log(11111)
        this.QingKongTaoCan() // 清空套餐信息
      },
      // 关闭成本卡
      Xcard_cost() {
        this.Xcard = false
        this.shade = false
        this.CostCard_HouZhui = [] // 图片后缀清空
        this.CostCard_Image = [] // 图片64清空
      },




      // 点击成本卡
      card_cai(index, rows) {

        for (let i in this.tableDataSS) {
          this.tableDataSS[i].mater_disabled = false
        }

        this.Xcard = true
        this.shade = true
        this.CostCard_Img = []
        // 成本卡选中的菜品
        this.costCard_food = rows[index]
        console.log('=====', this.$store.state.userInfo.ent_info.ent_id, this.costCard_food.prod_id, this
          .costCard_food);
        query_cost_info_detail({
          data: {
            ent_id: this.$store.state.userInfo.ent_info.ent_id,
            prod_id: this.costCard_food.prod_id,
          }
        }).then((res) => {
          // console.log(res);
          if (res.code == 200) {
            console.log(res, '成本卡的信息明细', res.body.data)
            let ChengBencard = []
            if (res.body.data == 'null') {
              this.card_updata = false
              if (this.costCard_food.prod_type == '2') {
                // 查询套餐的成本列表  // 查询套餐的成本列表
                query_combo_cost_list({
                  data: {
                    ent_id: this.$store.state.userInfo.ent_info.ent_id,
                    prod_id: this.costCard_food.prod_id
                  }
                }).then((res) => {
                  console.log(res, '查询套餐的成本列表', res.body.data);
                  if (res.code == 200) {
                    let TaoCancaipin = res.body.data
                    console.log('TaoCancaipin', TaoCancaipin)
                    for (let itemData of TaoCancaipin) {
                      console.log('itemDa', itemData);
                      // this.tableDataS
                      ChengBencard.push({
                        mater_id: itemData.mater_id,
                        mater_name: itemData.mater_name,
                        mater_spec: itemData.mater_spec,
                      })
                    }
                    this.tableDataS = ChengBencard // 获取到的套餐的原材料


                    // let arr=[]
                    // TaoCancaipin.forEach(item => {
                    //   if(arr[item.mater_id]){
                    //     arr[item.mater_id].mater_spec=arr[item.mater_id].mater_spec+1
                    //   }else{
                    //     arr[item.mater_id]=item
                    //   }
                    // });

                    // console.log(arr)
                    // // 
                    // for (const i in TaoCancaipin) {
                    //   for (const item of TaoCancaipin) {
                    //     if (TaoCancaipin[i].mater_id==item.mater_id) {
                    //       console.log('====',TaoCancaipin[i]);
                    //     }
                    //   }
                    // }

                  }
                })
              }
            } else {
              this.card_updata = true
            }
            let date1 = res.body.data
            let obj = {
              selling_dish_name: this.costCard_food.prod_name, // 菜品名称
              value: this.costCard_food.cat_name, // 菜品分类
              selling_time: date1.cook_time, // 烹饪时间
              textarea: date1.produce_step, // 制作步骤
              expense: date1.produce_cost, // 制造费用
              selling_selling_price: date1.price_sale, // 售价
              cost: date1.price_cost, // 成本
              gross: date1.gross_profit, // 毛利
              profit: date1.rate_gross_profit // 毛利率
            }
            this.tableDataS = date1.list_material
            this.form_vegetable = obj

            if (date1.cost_pic != undefined && date1.cost_pic != '') {
              // let Card_img = []
              let Card_img = ((date1.cost_pic).split(' '))
              this.CostCard_Img = Card_img.map(item => {
                console.log(item);
                return {
                  name: item,
                  url: item
                }
              })
            }
            console.log('成本卡', this.CostCard_Img);
          }
        })
      },
      // 表格移除
      deleteRow(index, rows) {
        let self = this
        // console.log('index',index,'rows',rows);
        delete_prod_info({
          data: {
            // ent_id:self.$store.state.ent_id,
            ent_id: this.$store.state.userInfo.ent_info.ent_id,
            user_id: self.$store.state.user_id, //用户id
            prod_ids: rows[index].prod_id
          }
        }).then((res) => {
          console.log('res', res);
          if (res.code == '10141') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.obtain_CaiPin()
          }
          if (res.code == 10142) {
            this.$message({
              message: '删除失败',
              type: 'success'
            })
          }
        })
        // rows.splice(index, 1)
      },
      // 路由跳转
      structure() {
        this.$router.push('/Classification')
      },
      // 点击前方列表的多选框 选中的数据
      handleSelectionChange(val) {
        console.log('sdfgbhnjm,l;ewdfghjk', val);
        this.multipleSelection = val
      },
      // 图片移除事件
      handleRemove(file, fileList) {
        console.log('图片移除成功', file, fileList.length)
        this.ZengJiaImageLen = fileList.length
      },
      // 新增菜品的上传事件 当图片改变时触发 添加文件、上传成功和上传失败时都会被调用
      TianJiaFood_Pic(file, fileList) {
        console.log('上传图片', file, '||', fileList);
        // 修改图片的上传张数
        let imege = fileList.length
        this.ZengJiaImageLen = imege
        // 图片的后缀名
        this.BaseImg_HouZhui.push(file.name.substring(file.name.lastIndexOf('.'), file.name.length))
        console.log(this.BaseImg_HouZhui)
        this.getBase64(file.raw).then(res => {
          console.log('转base64后', res)
          this.BaseImg.push(res)

          console.log(this.BaseImg);


        })
      },
      // 转base64
      getBase64(file) {
        return new Promise(function (resolve, reject) {
          let reader = new FileReader();
          let imgResult = "";
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            imgResult = e.target.result.substring(
              e.target.result.indexOf(',') + 1,
              e.target.result.length
            )
          };
          reader.onerror = function (error) {
            reject(error);
          };
          reader.onloadend = function () {
            resolve(imgResult);
          };
        });
      },


      andlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      // 上传图片 成功后的函数
      handleSuccess(res, file, fileList) {
        console.log('上传图片,---成功后的函数', res, file, fileList);
        let imege = fileList.length
        this.imageLen = imege
      },


      // 成本卡菜品选择原材料   将选中的菜品id和名字传值
      onChangeStripe(index, item) {
        console.log(index, item);
        // console.log(val);
        // console.log('this.tableDataS',this.tableDataS);
        for (const i in this.tableDataSS) {
          if (item == this.tableDataSS[i].prod_id) {
            this.tableDataS[index].mater_id = this.tableDataSS[i].prod_id
            this.tableDataS[index].mater_name = this.tableDataSS[i].prod_name
            console.log(this.tableDataSS[i].prod_name);
            this.tableDataSS[i].mater_disabled = true
          }
        }
        console.log('this.tableDataS', this.tableDataS);
        // 在选择相同的原材料时会与上边的合并
        // for (const i in this.tableDataS) {
        //   if (item == this.tableDataS[i].mater_id) {
        //     this.tableDataS[i].mater_spec = this.tableDataS[i].mater_spec*1+1
        //   }
        // }
      },
      // onChangeStripe(val){
      //   console.log(val);
      //   console.log('this.tableDataS',this.tableDataS);
      // },
      // 成本卡的 添加行数
      addLine() {
        let newValue = {
          // mater_id: this.costCard_food.prod_id,
          mater_id: '',
          mater_name: '',
          mater_spec: '1',
        }
        if (!this.tableDataS) {
          this.tableDataS = []
        }
        this.tableDataS.push(newValue)
      },
      // 套餐菜品的新增    prod_id   qty spec
      TAOCANaddLine() {
        console.log(this.costCard_food);
        let newValue = {
          qty: '1',
          // mater_id: this.costCard_food.prod_id,
          // mater_name: '',
          // mater_spec: '',
          spec: '',
        }
        if (!this.tableDataA) {
          this.tableDataA = []
        }
        this.tableDataA.push(newValue)
        console.log('this.tableDataA', this.tableDataA);
      },
      // 转换时间的格式
      crtTimeFtt(val, row) {
        if (val != null) {
          let date = new Date(val);
          // return date.getFullYear() + '-' + ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date
          //   .getMonth() + 1)) + '-' + date.getDate()>9?date.getDate():'0'+date.getDate();
          return date.getFullYear() + '-' + ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date
            .getMonth() + 1)) + '-' + date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
        }
      },
      getChilder(listc) {

        listc.forEach(item => {

          if (this.allInfo[item.site_id]) {

          } else {
            this.allInfo[item.site_id] = item
          }
          if (item.list && item.list.length > 0) {
            this.getChilder(item.list)
          }
        });
      },
      // 编辑 所属仓库
      handleToCangKu(value) {
        console.log(value)
        let arr = []
        let arrName = []

        let StorageRoom = this.StorageRoom

        for (let a in StorageRoom) {
          if (StorageRoom[a].site_id == value) {
            arrName.push(StorageRoom[a].site_name)
          }
        }

        arr.push(value)
        this.newcangkuid = arr
        this.newcangkuming = arrName






return
        // let arr = []
        // let arrName = []

        // for (const i in value) {
        //   arr.push(value[i][value[i].length - 1])
        // }
        // // this.getChilder(this.StorageRoom)
        // // for (const i in arr) {
        // //   arrName.push(this.allInfo[arr[i]].site_name)
        // // }
        // console.log('=======',arr)
        // console.log(arrName)
        // console.log(this.allInfo)
        // this.newcangkuid = arr
        // this.newcangkuming = arrName
      },

      // 显示编辑
      BianJi_Btn(index, rows) {
        console.log('---------asdfsf')
        // 传递数据
        // console.log('index', index, 'rows', rows);
        console.log('需要编辑的菜品', rows[index])
        let BianJi_from = rows[index]
        this.BianJi_PanDuan_XinXi = BianJi_from.prod_type //判断添加是菜品还是套餐
        this.prod_id_taocan = BianJi_from.prod_id
        if (BianJi_from.prod_type == '1') { // 1 菜品 2 套餐
          this.BianJi_CaiPin_PanDuan = '1' // 菜品---编辑
          this.showShade = true // 添加/编辑
          this.shade = true // 遮罩层

          // this.huanyuan_spec_list()
          query_prod_info_detail({
            data: {
              ent_id: BianJi_from.ent_id,
              prod_id: BianJi_from.prod_id
            }
          }).then((res) => {
            console.log('res菜品详情', res);
            let BianJi_from = res.body.data
            this.form = {
              text: BianJi_from.prod_name, // 菜品名称
              serialCai: BianJi_from.prod_code, // 菜品编号
              units: BianJi_from.unit, // 菜品单位
              // Choose: BianJi_from.site_name, // 所属仓库
              BatchNumber: BianJi_from.batch_no, // 批次号
              // priceMember: BianJi_from.price_member, // 会员价
              TimeValue: BianJi_from.date_valid, // 有效的时间日期
              KuFAngvalue: BianJi_from.cat_id, // 所属菜系的选择  id
              // newcangkumingA:BianJi_from.site_name // 仓库的名称
              newcangkumingA: BianJi_from.site_id, // 仓库的id
              flag_cost:BianJi_from.flag_cost
            }
            this.KuFAngvalue_name = BianJi_from.cat_name
            BianJi_from.spec_list.forEach((itemspec, index) => {
              console.log(itemspec)
              itemspec.titleSpec = '方案' + (index + 1),
                itemspec.name = (index + 1) + ''
            })
            this.spec_list = BianJi_from.spec_list

          })
          this.newcangkuming = BianJi_from.site_name.split(',')
          this.newcangkuid = BianJi_from.site_id.split(',')
          this.newcangkumingA = BianJi_from.site_name // 存仓库名称
          this.bianjicaipin_prod_id = BianJi_from.prod_id
          // let BianJi_from_img = []


          if (BianJi_from.prod_pic != undefined && BianJi_from.prod_pic != '') {
            let BianJi_from_img = (BianJi_from.prod_pic).split(' ')

            // console.log(BianJi_from.prod_pic,'BianJi_from.prod_pic', BianJi_from_img);
            this.ZengJiaImageLen = BianJi_from_img.length
            this.BianJi_Img = BianJi_from_img.map(item => {
              return {
                name: item,
                url: item
              }
            })

          }

        } else if (BianJi_from.prod_type == 2) { // 1 菜品 2 套餐
          // 需要编辑的套餐区域  // 查询套餐的菜品列表
          query_combo_prod_list({
            data: {
              ent_id: this.$store.state.userInfo.ent_info.ent_id,
              prod_id: BianJi_from.prod_id
            }
          }).then((res) => {
            console.log('查询套餐的信息res', res.body.data);
            let arr = res.body.data
            console.log(arr)
            for (const i in arr) {
              if (arr[i].spec_id) {
                arr[i].prod_id = arr[i].spec_id
              }
            }
            this.tableDataA = arr

          })
          this.BianJi_TaoCan_PanDuan = '1' // 将事件切换为套餐编辑的事件
          console.log('BianJi_from', BianJi_from);
          // 编辑套餐前的反显
          this.cost_get = true
          this.shade = true
          // ========= 新增套餐   新增分类
          this.form_cost = {
            text: BianJi_from.prod_name, // 菜品名称
            serial: BianJi_from.prod_code, // 菜品编号
            speci: BianJi_from.spec, // 菜品规格
            units: BianJi_from.unit, // 菜品单位
            price: BianJi_from.price_uniform, // 统一价格
            adjust: BianJi_from.price_member, // 会员价
            morning: BianJi_from.price_morning, // 早市价格
            afternoon: BianJi_from.price_noon, // 午市价格
            night: BianJi_from.price_night, // 晚市价格
            price_takeout: BianJi_from.price_takeout, // 外卖价格
            value: BianJi_from.cat_name, // 所属菜系
            // Choose: '' // 选择菜品
          }
          console.log('BianJi_from.prod_pic', BianJi_from.prod_pic)
          if (BianJi_from.prod_pic != undefined && BianJi_from.prod_pic != '') {
            let BianJi_form_cost_img = (BianJi_from.prod_pic).split(' ')
            this.imageLen = BianJi_form_cost_img.length
            this.TaoCan_BianJi_TuPian = BianJi_form_cost_img.map(item => {
              return {
                name: item,
                url: item
              }
            })
          }


        }
      },
      //  新增菜品的事件  接口  事件
      addNewCaiPin() {
        let self = this


        if (this.form.TimeValue[0] != 0) {

          if (this.form.text != '' && this.form.BatchNumber != '' && this.form.TimeValue != '' &&
            this.form.KuFAngvalue != '' && this.newcangkuming != [] && this.form.units != '' && this.form.serialCai !=
            '') {


            let date = this.form
            // console.log('新增菜品新增菜品新增菜品', this.form);
            // let ZhuanHuaTime = this.crtTimeFtt(date.TimeValue)
            let spec_list_zhuan = JSON.parse(JSON.stringify(this.spec_list))
            let spec_list = spec_list_zhuan



            for (const i in spec_list) {

              if (!spec_list[i].price_member && !spec_list[i].price_morning && !spec_list[i].price_night && !spec_list[i].price_noon) {

              }else{
                 if (!spec_list[i].price_uniform) {
                  this.$message({
                    message: '请输入统一价格',
                    type: 'error'
                  })
                  return
                }
              }




              //             price_member: "15"
              // price_morning: "10"
              // price_night: "19"
              // price_noon: "18"

              if (spec_list[i].price_morning && spec_list[i].price_member) {
                if (spec_list[i].price_morning * 1 < spec_list[i].price_member * 1) {
                  this.$message({
                    message: '早午晚市价格不能低于会员价',
                    type: 'error'
                  })
                  return
                  // 333333333
                }
              }

              if (spec_list[i].price_night && spec_list[i].price_member) {
                if (spec_list[i].price_night * 1 < spec_list[i].price_member * 1) {
                  this.$message({
                    message: '早午晚市价格不能低于会员价',
                    type: 'error'
                  })
                  return
                }
              }

              if (spec_list[i].price_noon && spec_list[i].price_member) {
                if (spec_list[i].price_noon * 1 < spec_list[i].price_member * 1) {
                  this.$message({
                    message: '早午晚市价格不能低于会员价',
                    type: 'error'
                  })
                  return
                }
              }

            }


            for (let item of spec_list) {
              delete item.titleSpec
              delete item.name
              delete item.price_conflictA
              delete item.price_conflictB
            }
            console.log('规格信息', spec_list);
            /*
            spec_title: '', //规格名称
            price_uniform: '', // 统一价格
            prod_spec: '', // 菜品规格
            */
            // for (let i in this.spec_list) {
            //   if (this.spec_list[i].spec_title=''&&this.spec_list[i].prod_spec) {
            //     spec_list=[]
            //   }
            // }

            // 需要新增的数据
            let add_caipin = {
              ent_id: this.$store.state.userInfo.ent_info.ent_id,
              user_id: self.$store.state.user_id, //用户id
              prod_type: '1', // 上传菜品数据 1、菜品 2、套餐
              prod_name: date.text, // 菜品名称
              prod_code: date.serialCai, // 菜品编号
              flag_cost: date.flag_cost, // 成本卡
              unit: date.units, // 菜品单位
              prod_pic: this.BaseImg.join(' '), //图片b64 产品图片
              prod_pic_type: this.BaseImg_HouZhui.join(' '), // 后缀 产品图片后缀
              site_id: this.newcangkuid.join(','), // 所属仓库的选择id
              site_name: this.newcangkuming.join(','), // 所属仓库的选择的名称
              cat_name: this.KuFAngvalue_name, // 所属菜系的选择
              cat_id: date.KuFAngvalue, // 所属菜系的选择id
              batch_no: date.BatchNumber.length != 0 ? date.BatchNumber + '' : null, // 批次号
              // price_member: date.priceMember,
              // date_valid: ZhuanHuaTime, // 有效的时间日期
              date_valid: date.TimeValue + '', // 有效的天数日期
              spec_list: spec_list, // 菜品规格 方案数组对象
            }
            console.log('新增菜品+菜品规格方案组', add_caipin)
            // return
            console.log('retur return 新增菜品+菜品规格方案组');
            insert_prod_info({
              data: add_caipin
            }).then((res) => {

              console.log('新增菜品', res);
              if (res.code == '10157') {
                this.$message({
                  message: '新增成功',
                  type: 'success'
                })
                this.Init()
                this.QingKongCaiPinDAn() // 清空表单
                this.obtain_CaiPin() // 获取菜品的信息列表
                this.close() // 关闭新增菜单
              }
              if (res.code == '600') {
                this.$message({
                  message: '添加失败，已存在产品编码',
                  type: 'error'
                })
                // this.QingKongCaiPinDAn() // 清空表单
                // this.obtain_CaiPin() // 获取菜品的信息列表
                // this.close() // 关闭新增菜单
              }
              if (res.code =='1200'||res.code =='1100') {
                this.$message({
                  message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                  type: 'error'
                })
              }
            })
          } else {
            let Tishi = []
            if (this.form.text == '') {
              Tishi.push('菜品名称')
            }
            if (this.form.BatchNumber == '') {
              Tishi.push('批次号')
            }
            if (this.form.serialCai == '') {
              Tishi.push('菜品编号')
            }
            if (this.form.TimeValue == '') {
              Tishi.push('有效的天数')
            }
            if (this.form.units == '') {
              Tishi.push('菜品单位')
            }
            if (this.newcangkuming.length < 1) {
              Tishi.push('所属仓库')
            }
            if (this.form.KuFAngvalue == '') {
              Tishi.push('所属菜系')
            }
            if (this.form.flag_cost == '') {
              Tishi.push('是否展示成本卡')
            }

            // let tishiStr = Tishi.split(',')
            console.log(this.tableDataA.length, '1', 'Tishi', Tishi);
            this.$message({
              message: `请将${Tishi.join()}的信息填写完整`,
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: '日期首位不能为0',
            type: 'warning'
          })
        }

      },
      // 保存修改/编辑后的菜品信息
      BaocunXiuGaiCaiPin() {
        let self = this
        // 44444444
        console.log(this.form.text); //菜品名
        console.log(this.form.serial); // serialCai  菜品编号
        console.log(this.form.BatchNumber); // 批次号
        console.log(this.form.TimeValue); // 有效的天数 有效的时间日期
        console.log(this.form.KuFAngvalue); // 所属菜系的选择  id
        console.log(this.newcangkuming); // this.newcangkumingA 选择仓库 ok
        console.log(this.form.units); // 菜品单位
        console.log(this.$refs.editCat.uploadFiles)
        // spec_list



        if (this.form.text != '' && this.form.BatchNumber != '' && this.form.TimeValue != '' &&
          this.form.KuFAngvalue != '' && this.newcangkumingA != [] && this.form.units != '') {
            
          // 编辑菜品信息
          console.log('进行编辑信息', this.form);
          let date = this.form
          // let ZhuanHuaTime = this.crtTimeFtt(date.TimeValue)
          let spec_list = this.spec_list

          for (const i in spec_list) {

               if (!spec_list[i].price_member && !spec_list[i].price_morning && !spec_list[i].price_night && !spec_list[i].price_noon) {

              }else{
                 if (!spec_list[i].price_uniform) {
                  this.$message({
                    message: '请输入统一价格',
                    type: 'error'
                  })
                  return
                }
              }
            //             price_member: "15"
            // price_morning: "10"
            // price_night: "19"
            // price_noon: "18"

            if (spec_list[i].price_morning && spec_list[i].price_member) {
              if (spec_list[i].price_morning * 1 < spec_list[i].price_member * 1) {
                this.$message({
                  message: '早午晚市价格不能低于会员价',
                  type: 'error'
                })
                return
                // 333333333
              }
            }

            if (spec_list[i].price_night && spec_list[i].price_member) {
              if (spec_list[i].price_night * 1 < spec_list[i].price_member * 1) {
                this.$message({
                  message: '早午晚市价格不能低于会员价',
                  type: 'error'
                })
                return
              }
            }

            if (spec_list[i].price_noon && spec_list[i].price_member) {
              if (spec_list[i].price_noon * 1 < spec_list[i].price_member * 1) {
                this.$message({
                  message: '早午晚市价格不能低于会员价',
                  type: 'error'
                })
                return
              }
            }

          }

          for (let item of spec_list) {





            delete item.titleSpec
            delete item.name
          }

          let imgListUp = this.$refs.editCat.uploadFiles
          let arry = []
          for (const i in imgListUp) {
            if (imgListUp[i].status == 'success') {
              arry.push(imgListUp[i].url)
            }
          }
          for (const i in this.BaseImg) {
            arry.push(this.BaseImg[i])
          }

          // 需要传递的修改后数据
          let add_bianji_caipin = {
            ent_id: this.$store.state.userInfo.ent_info.ent_id, // 企业id
            user_id: self.$store.state.user_id, //用户id
            prod_type: '1', // 上传菜品数据 1、菜品 2、套餐
            prod_name: date.text, // 菜品名称
            flag_cost: date.flag_cost, // 成本卡展示不展示
            prod_code: date.serialCai, // 菜品编号
            unit: date.units, // 菜品单位
            prod_pic: arry.join(' '), //图片b64 产品图片
            prod_pic_type: this.BaseImg_HouZhui.join(' '), // 后缀 产品图片后缀
            site_id: this.newcangkuid.join(','), // 所属仓库的选择id
            site_name: this.newcangkuming.join(','), // 所属仓库的选择的名称
            cat_name: this.KuFAngvalue_name, // 所属菜系的选择
            cat_id: date.KuFAngvalue, // 所属菜系的选择id
            batch_no: date.BatchNumber.length != 0 ? date.BatchNumber + '' : null, // 批次号
            // price_member: date.priceMember,
            // date_valid: ZhuanHuaTime, // 有效的时间日期
            date_valid: date.TimeValue + '', // 有效的时间日期
            prod_id: this.bianjicaipin_prod_id, // 菜品得id
            spec_list: spec_list,
          }
          console.log('进行编辑菜品后保存修改的信息', add_bianji_caipin)

          update_prod_info({
            data: add_bianji_caipin
          }).then((res) => {
            console.log('进行编辑菜品后保存修改的信息', res);
            if (res.code == '10137') {
              this.$message({
                message: '菜品编辑成功',
                type: 'success'
              })
              this.QingKongCaiPinDAn() // 清空表单
              this.obtain_CaiPin() // 获取菜品的信息列表
              this.close() // 关闭新增菜单
            }
            if (res.code =='1200'||res.code =='1100') {
              this.$message({
                message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                type: 'error'
              })
            }
          })
        } else {
          let Tishi = []
          if (this.form.text == '') {
            Tishi.push('菜品名称')
          }
          if (this.form.Cai == '') {
            Tishi.push('菜品编号')
          }
          if (this.form.BatchNumber == '') {
            Tishi.push('菜品规格')
          }
          if (this.form.TimeValue == {}) {
            Tishi.push('菜系')
          }
          if (this.form.units == '') {
            Tishi.push('菜品单位')
          }
          if (this.tableDataA.length < 1) {
            Tishi.push('选择菜品')
          }
          // let tishiStr = Tishi.split(',')
          console.log(this.tableDataA.length, '1', 'Tishi', Tishi);


          this.$message({
            message: '请将信息填写完整',
            type: 'warning'
          })
        }


      },

      // -------------套餐区域
      // 获取套餐 分类的列表
      SetMeal_add_fenlei(val) {
        console.log('套餐所属菜系', val)
        // TaoCan_xinxi
        this.classify.forEach((item) => {
          if (item.cat_id == val) {
            // 选中的分类全部信息
            this.TaoCan_xinxi = item
          }
        })
        console.log('this.TaoCan_xinxi', this.TaoCan_xinxi);
      },
      // 元套菜品的选择
      handleChange_cuisine(foodChange) {
        let CaiPinXinXi = []
        foodChange.forEach((item) => {
          CaiPinXinXi.push({
            prod_id: item.prod_id,
            qty: item.number,
            spec: item.spec
          })
        })
        console.log('编辑套餐选择菜品', CaiPinXinXi);
        this.TaoCanCaiPin = CaiPinXinXi
      },
      // 套餐的上传图片
      TianJiaTaoCan(file, fileList) {
        console.log('上传图片', file, '||', fileList);
        let imege = fileList.length
        this.imageLen = imege
        // 图片的后缀名
        this.TaoCan_Base_HouZhui.push(file.name.substring(file.name.lastIndexOf('.'), file.name.length))
        console.log(this.TaoCan_Base_HouZhui)
        // 加密 转base64
        this.getBase64(file.raw).then(res => {
          console.log('转base64后', res)
          // 加密 转base64后的数据
          // this.TaoCan_Img = res
          this.TaoCan_Img.push(res)
        })
      },

      //  新增套餐 的确认按钮 编辑套餐按钮
      SetMeal_Confirm() {

        let self = this

        console.log(this.tableDataA, '保存的对象')

        console.log(this.TaoCan_xinxi, JSON.stringify(this.TaoCan_xinxi))

        // console.log(this.form_cost.units)
        // console.log(this.tableDataA);
        if (this.BianJi_TaoCan_PanDuan == '0') {
          console.log('新增套餐的确认按钮', this.tableDataA)

          let boss = this.tableDataA.every(ress => {
            console.log(ress, 'aaaaaaaaaaaaaaaaa')
            return ress.prod_id != undefined
          })

          let date = this.form_cost


         if (!date.morning && !date.afternoon && !date.night && !date.adjust) {

              }else{
                 if (!date.price) {
                  this.$message({
                    message: '请输入统一价格',
                    type: 'error'
                  })
                  return
                }
              }



          // if (!date.price) {
          //   this.$message({
          //     message: '请输入统一价格',
          //     type: 'error'
          //   })
          //   return
          // }
          // 44444444444444444
          if (date.morning && date.adjust) {
            if (date.adjust * 1 > date.morning * 1) {
              this.$message({
                message: '早午晚市价格不能低于会员价',
                type: 'error'
              })
              console.log(date.adjust * 1, date.morning * 1)
              return
            }
          }


          if (date.afternoon && date.adjust) {
            if (date.adjust * 1 > date.afternoon * 1) {
              this.$message({
                message: '早午晚市价格不能低于会员价',
                type: 'error'
              })
              console.log(date.adjust * 1, date.afternoon * 1, '5')
              return
            }
          }

          if (date.night && date.adjust) {
            if (date.adjust * 1 > date.night * 1) {
              this.$message({
                message: '早午晚市价格不能低于会员价',
                type: 'error'
              })
              console.log(date.adjust * 1, date.night * 1)
              return
            }
          }



          if (boss && date.text != '' && date.serial != '' && date.speci != '' &&
            date.units != '' && this.tableDataA.length > 0) {
            // 需要新增的数据
            let add_taocan = {
              ent_id: this.$store.state.userInfo.ent_info.ent_id,
              user_id: self.$store.state.user_id, //用户id
              prod_type: '2', // 上传菜品数据 1、菜品 2、套餐
              prod_name: date.text, // 套餐名称
              prod_code: date.serial, // 菜品编号
              spec: date.speci, // 菜品规格
              unit: date.units, // 菜品单位
              prod_pic: this.TaoCan_Img.join(' '), //图片b64 套餐图片
              prod_pic_type: this.TaoCan_Base_HouZhui.join(' '), // 后缀 产品图片后缀

              cat_name: this.TaoCan_xinxi.cat_name, // 所属菜系的选择
              cat_id: this.TaoCan_xinxi.cat_id, // 所属菜系的选择id//
              // combo_prod_list: this.TaoCanCaiPin, // 传入的所选的菜品
              combo_prod_list: this.tableDataA, // 传入的所选的菜品

              price_uniform: date.price, // 统一价格
              price_member: date.adjust, // 会员价
              price_morning: date.morning, // 早市价格
              price_noon: date.afternoon, // 午市价格
              price_night: date.night, // 晚市价格
              price_takeout: date.price_takeout, // 外卖价格





            }
            console.log('传递进接口的数据', add_taocan)
            // return
            insert_prod_info({
              data: add_taocan
            }).then((res) => {
              console.log('新增套餐', res)
              if (res.code == '10157') {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
                this.QingKongTaoCan() // 清空表单
                this.obtain_CaiPin() // 获取菜品的信息列表
                this.close_cost() // 关闭新增套餐
              } else if (res.code == '500') {
                this.$message({
                  message: '添加失败',
                  type: 'success'
                })
              } else if (res.code =='1200'||res.code =='1100') {
                this.$message({
                  message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                  type: 'error'
                })
              }
            })
          } else {
            // && JSON.stringify(this.TaoCan_xinxi)!='{}'
            let Tishi = []
            if (date.text == '') {
              Tishi.push('菜品名称')
            }
            if (date.serial == '') {
              Tishi.push('菜品编号')
            }
            if (date.speci == '') {
              Tishi.push('菜品规格')
            }
            // if ( JSON.stringify(this.TaoCan_xinxi) == "{}") {
            //   Tishi.push('菜系')
            // }
            if (date.units == '') {
              Tishi.push('菜品单位')
            }
            if (this.tableDataA.length < 1) {
              Tishi.push('选择菜品')
            }
            if (boss == false) {
              Tishi.push('选择菜品')
            }

            // let tishiStr = Tishi.split(',')
            console.log(this.tableDataA.length, '1', 'Tishi', Tishi);
            this.$message({
              message: `请将${Tishi.join()}的信息填写完整`,
              type: 'warning'
            })
          }

        } else if (this.BianJi_TaoCan_PanDuan == '1') {
          // 编辑套餐  的信息



          let boss = this.tableDataA.every(ress => {
            console.log(ress, 'aaaaaaaaaaaaaaaaa')
            return ress.prod_id != undefined
          })
          console.log(boss);

          let date = this.form_cost

   if (!date.morning && !date.afternoon && !date.night && !date.adjust) {

              }else{
                 if (!date.price) {
                  this.$message({
                    message: '请输入统一价格',
                    type: 'error'
                  })
                  return
                }
              }
          if (date.morning && date.adjust) {
            if (date.adjust * 1 > date.morning * 1) {
              this.$message({
                message: '早午晚市价格不能低于会员价',
                type: 'error'
              })
              console.log(date.adjust * 1, date.morning * 1)
              return
            }
          }


          if (date.afternoon && date.adjust) {
            if (date.adjust * 1 > date.afternoon * 1) {
              this.$message({
                message: '早午晚市价格不能低于会员价',
                type: 'error'
              })
              console.log(date.adjust * 1, date.afternoon * 1, '5')
              return
            }
          }

          if (date.night && date.adjust) {
            if (date.adjust * 1 > date.night * 1) {
              this.$message({
                message: '早午晚市价格不能低于会员价',
                type: 'error'
              })
              console.log(date.adjust * 1, date.night * 1)
              return
            }
          }



          if (boss && date.text != '' && date.serial != '' && date.speci != '' &&
            date.units != '' && this.tableDataA.length > 0) {

            let imgListUp = this.$refs.bianjitaocan.uploadFiles
            let arry = []
            console.log('imgListUp', imgListUp);
            for (const i in imgListUp) {
              if (imgListUp[i].status == 'success') {
                arry.push(imgListUp[i].url)

              }
            }
            for (const i in this.TaoCan_Img) {
              arry.push(this.TaoCan_Img[i])
            }
            // 需要编辑的数据
            let add_bianji_caipin = {
              prod_id: this.prod_id_taocan, // 套餐的id
              // ent_id:self.$store.state.ent_id, // 企业id
              ent_id: this.$store.state.userInfo.ent_info.ent_id,
              user_id: self.$store.state.user_id, //用户id
              prod_type: '2', // 上传菜品数据 1、菜品 2、套餐
              prod_name: date.text, // 套餐名称
              prod_code: date.serial, // 菜品编号
              spec: date.speci, // 菜品规格
              unit: date.units, // 菜品单位
              // prod_pic: this.TaoCan_Img.join(' '), //图片b64 套餐图片
              prod_pic: arry.join(' '), //图片b64 套餐图片
              prod_pic_type: this.TaoCan_Base_HouZhui.join(' '), // 后缀 产品图片后缀

              cat_name: this.TaoCan_xinxi.cat_name, // 所属菜系的选择
              cat_id: this.TaoCan_xinxi.cat_id, // 所属菜系的选择id
              combo_prod_list: this.tableDataA, // 传入的所选的菜品、

              price_uniform: date.price, // 统一价格
              price_member: date.adjust, // 会员价
              price_morning: date.morning, // 早市价格
              price_noon: date.afternoon, // 午市价格
              price_night: date.night, // 晚市价格
              price_takeout: date.price_takeout // 外卖价格
            }
            console.log('bia==编辑传递进接口的数据', add_bianji_caipin);
            update_prod_info({
              data: add_bianji_caipin
            }).then((res) => {
              console.log('编辑信息进行', res);
              if (res.code == '10137') {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
                this.QingKongTaoCan() // 清空表单
                this.obtain_CaiPin() // 获取菜品的信息列表
                this.close_cost() // 关闭新增套餐
              }
              if (res.code == '600') {
                this.$message({
                  message: '添加失败，已存在产品编码',
                  type: 'error'
                })
                // this.QingKongTaoCan() // 清空表单
                // this.obtain_CaiPin() // 获取菜品的信息列表
                // this.close_cost() // 关闭新增套餐
              }
              if (res.code =='1200'||res.code =='1100') {
                this.$message({
                  message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                  type: 'error'
                })
              }
            })
          } else {
            let Tishi = []
            if (date.text == '') {
              Tishi.push('菜品名称')
            }
            if (date.serial == '') {
              Tishi.push('菜品编号')
            }
            if (date.speci == '') {
              Tishi.push('菜品规格')
            }
            // if ( JSON.stringify(this.TaoCan_xinxi) == "{}") {
            //   Tishi.push('菜系')
            // }
            if (date.units == '') {
              Tishi.push('菜品单位')
            }
            if (this.tableDataA.length < 1) {
              Tishi.push('选择菜品')
            }
            if (boss == false) {
              Tishi.push('选择菜品')
            }
            // let tishiStr = Tishi.split(',')
            console.log(this.tableDataA.length, '1', 'Tishi', Tishi);
            this.$message({
              message: `请将${Tishi.join()}的信息填写完整`,
              type: 'warning'
            })
          }

        }
      },
      // 批量删除菜品
      PiLiangShanChu() {
        let self = this
        console.log('删除');
        let Del_CaiPin = this.multipleSelection
        console.log('Del_CaiPin', Del_CaiPin)
        let Del_caiPin_id = JSON.stringify(Del_CaiPin)
        if (Del_caiPin_id == '[]') {
          this.$message({
            message: '请选择要删除的菜品信息',
            type: 'warning'
          })
        } else {
          let Del_caiPin_prod_id = [] // 存放选中的prod_id 组成的数组
          Del_CaiPin.forEach((item) => {
            Del_caiPin_prod_id.push(item.prod_id)
          })
          console.log(Del_caiPin_prod_id, 'Del_caiPin_idDel_caiPin_id');
          delete_prod_info({
            data: {
              // ent_id:self.$store.state.ent_id, // 企业id
              ent_id: this.$store.state.userInfo.ent_info.ent_id,
              user_id: self.$store.state.user_id, //用户id
              prod_ids: Del_caiPin_prod_id.join(',')
            }
          }).then((res) => {
            console.log('批量删除', res);
            if (res.code = 10141) {
              this.obtain_CaiPin()
            }
          })
        }

      },

      // ------- ------- ------- -------成本卡
      // 成本卡的上传事件 当图片改变时触发 添加文件、上传成功和上传失败时都会被调用
      CostCard_Pic(file, fileList) {
        console.log('上传图片', file, '||', fileList);
        // 修改图片的上传张数
        let imege = fileList.length
        this.ZengJiaImageLen = imege
        // 图片的后缀名
        // this.CostCard_HouZhui = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
        // console.log(this.CostCard_HouZhui)  // 成本卡 产品图片后缀
        this.CostCard_HouZhui.push(file.name.substring(file.name.lastIndexOf('.'), file.name.length))
        console.log(this.CostCard_HouZhui) // 成本卡 产品图片后缀
        this.getBase64(file.raw).then(res => {
          console.log('转base64后', res)
          this.CostCard_Image.push(res)
        })
      },
      costCard_Types_of_Courses(val) {
        this.costCard_cat_id = val
        // this.greens.forEach()
      },
      // 成本卡确认按钮
      costCard_tianjia() {
        this.submitLoading = true
        this.yanzheng()
        console.log('this.card', this.card_item);


        if (!this.card_item) {
          this.$message({
            message: '请将信息填写完整',
            type: 'warning'
          })
        } else {
          // console.log(this.tableDataS);
          // console.log( this.costCard_food);
          console.log(this.costCard_food);
          let cost_card = this.form_vegetable
          console.log('cost_card', cost_card);

          let costCard = {
            ent_id: this.$store.state.userInfo.ent_info.ent_id,
            user_id: this.$store.state.user_id,
            cat_id: this.costCard_cat_id != '' ? this.costCard_cat_id : this.costCard_food.cat_id, //菜品类型
            // CostCard_Image
            cost_pic: this.CostCard_Image.join(' '), //图片b64 产品图片
            cost_pic_type: this.CostCard_HouZhui.join(' '), // 后缀 成本卡 产品图片后缀

            // selling_dish_name:cost_card.selling_dish_name, // 菜品名称
            // value: '', // 菜品分类
            prod_id: this.costCard_food.prod_id, //	菜id
            cook_time: cost_card.selling_time, // 烹饪时间
            produce_step: cost_card.textarea, // 制作步骤
            produce_cost: cost_card.expense, // 制造费用
            price_sale: cost_card.selling_selling_price, // 售价
            price_cost: cost_card.cost, // 成本
            gross_profit: cost_card.gross, // 毛利
            rate_gross_profit: cost_card.profit, // 毛利率
            list_material: this.tableDataS
          }
          // this.tableDataS  // 添加菜品材料
          console.log('costCard', costCard);
          // return
          insert_cost_info({
            data: costCard
          }).then((res) => {
            this.submitLoading = false
            console.log('=========', res);
            if (res.code == 200) {
              this.Xcard_cost()
            }
          })
        }
      },
      yanzheng() {
        // console.log(this.form_vegetable.selling_dish_name) // 菜品名称
        // console.log(this.form_vegetable.value) // 菜品分类
        // console.log(this.form_vegetable.selling_time); // 烹饪时间
        // console.log(this.form_vegetable.textarea); // 制作步骤
        // console.log(this.form_vegetable.expense); // 制造费用
        // console.log(this.form_vegetable.selling_selling_price); // 售价
        // console.log(this.form_vegetable.cost);  // 成本
        // console.log(this.form_vegetable.gross); // 毛利
        // console.log(this.form_vegetable.profit); // 毛利率
        // console.log(this.tableDataS);
        let arrvegetable = []
        for (const key in this.form_vegetable) {
          console.log('====',this.form_vegetable[key])
          arrvegetable.push(this.form_vegetable[key])
        }
        this.card_item = arrvegetable.every((item) => {
          return item != undefined && item != ''
        })
      },
      // 更新 成本卡
      costCard_updata() {
        this.yanzheng()
        console.log(this.card_item);
        if (!this.card_item) {
          this.$message({
            message: '请将信息填写完整',
            type: 'warning'
          })
        } else {
          console.log(this.costCard_food);
          let cost_card = this.form_vegetable
          console.log('cost_card', cost_card);

          let imgListUp = this.$refs.chengbenKa.uploadFiles
          let arry = []
          for (const i in imgListUp) {
            if (imgListUp[i].status == 'success') {
              arry.push(imgListUp[i].url)

            }
          }
          for (const i in this.CostCard_Image) {
            arry.push(this.CostCard_Image[i])
          }

          let costCard = {
            ent_id: this.$store.state.userInfo.ent_info.ent_id,
            cat_id: this.costCard_cat_id != '' ? this.costCard_cat_id : this.costCard_food.cat_id, //菜品类型

            // CostCard_Image
            // cost_pic: this.CostCard_Image.join(' '), //图片b64 产品图片
            cost_pic: arry.join(' '), //图片b64 产品图片
            cost_pic_type: this.CostCard_HouZhui.join(' '), // 后缀 成本卡 产品图片后缀

            // selling_dish_name:cost_card.selling_dish_name, // 菜品名称
            // value: '', // 菜品分类
            prod_id: this.costCard_food.prod_id, //	菜id
            cook_time: cost_card.selling_time, // 烹饪时间
            produce_step: cost_card.textarea, // 制作步骤
            produce_cost: cost_card.expense, // 制造费用
            price_sale: cost_card.selling_selling_price, // 售价
            price_cost: cost_card.cost, // 成本
            gross_profit: cost_card.gross, // 毛利
            rate_gross_profit: cost_card.profit, // 毛利率
            list_material: this.tableDataS,
            user_id: this.$store.state.user_id
          }
          console.log('更新成本卡', costCard);
          // return
          update_cost_info({
            data: costCard
          }).then((res) => {
            this.submitLoading = false
            console.log('=========', res);
            if (res.code == 200) {
              this.Xcard_cost()
            }
          })
        }

      },

      // 将添加菜品后的单子清空
      QingKongCaiPinDAn() {
        this.form = {
          text: '', // 菜品名称
          serialCai: '', // 菜品编号
          speci: '', // 菜品规格
          units: '', // 菜品单位
          price: '', // 统一价格
          adjust: '', // 会员价
          morning: '', // 早市价格
          afternoon: '', // 午市价格
          night: '', // 晚市价格
          // Choose: '', // 所属仓库
          newcangkumingA: '', // 所属仓库
          BatchNumber: '', // 批次号
          // priceMember: '',
          TimeValue: '', // 有效的天数 有效的时间日期
          KuFAngvalue: '', // 所属菜系的选择  id
          price_takeout: '', // 外卖价格
        }
        this.BaseImg = []
        this.ZengJiaImageLen = '0'
        this.BianJi_Img = [],
          this.BaseImg_HouZhui = []
      },
      // 将添加套餐后的单子清空
      QingKongTaoCan() {
        this.form_cost = {
          text: '', // 菜品名称
          serial: '', // 菜品编号
          speci: '', // 菜品规格
          units: '', // 菜品单位
          price: '', // 统一价格
          adjust: '', // 会员价
          morning: '', // 早市价格
          afternoon: '', // 午市价格
          night: '', // 晚市价格
          // Choose: '', // 选择菜品
          value: '', // 套餐菜系
          price_takeout: '', // 外卖价格
        }
        for (const i in this.Cuisines_tree) {
          this.Cuisines_tree[i].prod_name = this.Cuisines_tree[i].cat_name
          this.Cuisines_tree[i].prod_id = this.Cuisines_tree[i].prod_id
          if (this.Cuisines_tree[i].prod_children) {
            for (const j in this.Cuisines_tree[i].prod_children) {
              this.Cuisines_tree[i].prod_children[j].prod_id = this.Cuisines_tree[i].prod_children[j].prod_id
              this.Cuisines_tree[i].prod_children[j].prod_name = this.Cuisines_tree[i].prod_children[j].prod_name
              this.Cuisines_tree[i].prod_children[j].isuse = false
              if (this.Cuisines_tree[i].prod_children[j].spec_list && this.Cuisines_tree[i].prod_children[j].spec_list
                .length) {
                for (const n in this.Cuisines_tree[i].prod_children[j].spec_list) {
                  this.Cuisines_tree[i].prod_children[j].spec_list[n].prod_id = this.Cuisines_tree[i].prod_children[j]
                    .spec_list[n].spec_id
                  this.Cuisines_tree[i].prod_children[j].spec_list[n].prod_name = this.Cuisines_tree[i].prod_children[j]
                    .spec_list[n].spec_title
                  this.Cuisines_tree[i].prod_children[j].spec_list[n].isuse = false
                }
                this.Cuisines_tree[i].prod_children[j].prod_children = this.Cuisines_tree[i].prod_children[j].spec_list
              }


            }

          }

        }


        // 66666666666666666666666666666666666666666
        this.tableDataA = [],
          this.imageLen = '0'
        this.TaoCan_Img = [] // 套餐的base64清空
        this.TaoCan_BianJi_TuPian = []
        this.TaoCan_Base_HouZhui = []
      },
      // 还原选项卡列表
      huanyuan_spec_list() {
        this.spec_list = [{
          titleSpec: '方案1',
          name: '1',
          // content: 'Tab 1 content',
          spec_title: '', //规格名称
          price_uniform: '', // 统一价格
          prod_spec: '', // 菜品规格
          price_member: '', // 会员价
          price_morning: '', // 早市价格
          price_noon: '', // 午市价格
          price_night: '', // 晚市价格
          price_takeout: '' // 外卖价格
        }] // 清空列表
        // this.spec_listValue = '1';
      },

      // --------------------------------------
      MuMan_XiaZai() {
        const data = ['https://www.mlcbr.com/template/餐添大数-菜品导入模板.xlsx']
        this.multiDownLoad(data)
      },
      //   下载多个文件
      multiDownLoad(val) {
        console.log('===============', val);
        for (let i = 0; i < val.length; i++) {
          const iframe = document.createElement('iframe')
          iframe.style.display = 'none'
          iframe.style.height = 0
          iframe.src = val[i]
          document.body.appendChild(iframe)
          setTimeout(res => {
            iframe.remove()
          }, 5 * 60 * 1000)
        }
      },
      QueRenShangChuan() {
        let self = this
        console.log('this.$refs.upload.uploadFiles', this.$refs.upload.uploadFiles)
        const list = []
        const date = this.$refs.upload.uploadFiles
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            alert('文件格式错误')
            return
          } else {
            const fileObj = list[i]
            const form = new FormData() // FormData 对象
            form.append('file', fileObj) // 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.$store.state.userInfo.ent_info.ent_id)
            console.log(form);
            // /import_customer_ai // / 导入客户信息--智能导入--多部门
            import_customer_ai({
              data: form
            }).then((res) => {
              console.log(res);
            })
          }
        }
      },
      //当上传的文件改变时
      ShangChuanChange(file, fileList) {
        console.log(file, '|', fileList);
      },
      //导入
      import_cat_file_up() {
        this.$refs.submit.uploadFile()

        // this.importDialogShow = false
        //
        this.import_cat_file_show = false
        // this.$router.go(0)
        this.obtain_CaiPin()



      },
      export_cat_file_down() {
        this.$refs.downFile.moban_xiazai()
        this.exportDialogShow = false
      },
      // 数据导出
      // ShuJuDaoChu () { },
      ...mapActions(['get_cat_list']),

    }
  }
</script>

<style scoed lang="scss">
  .el-main {
    background: #f6f6fa;
  }

  .Essential {
    width: 100%;
    color: #f6f6f3;
    background: url('../../../assets/img/beijing.png') no-repeat;
    height: 1000px;
    background-size: 100% 800px;

    .Essential-box {
      padding: 0 0 0 0;
      display: flex;
      justify-content: space-between;

      .Essential-ul {
        display: flex;
        padding: 54px 0 0 97px;

        li {
          font-size: 20px;
          color: #181616;
          width: 240px;
          font-weight: 500;
        }

        .Essential-li {
          color: #fe5745;
        }
      }

      .Essential-firm {
        padding: 38px 50px 0 0;

        .Essential-noe {
          color: #333;
        }

        &:first-of-type p {
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          color: #fc4353;
        }
      }
    }

    .Essential-state {
      .Essential-state-box {
        padding: 0 68px 0 65px;
        display: flex;
        justify-content: space-between;
      }

      ul {
        display: flex;
        margin-top: 43px;
        width: 50% !important;
        justify-content: center;
        align-items: center;

        li {
          color: #666;
          font-size: 18px;
          display: flex;
          justify-content: center;
          flex: 1;
          margin-right: 10px;

          i {
            margin: 0 10px 0 0;
          }

          span {
            margin-top: 4px;
          }

          &:hover {
            cursor: pointer;
          }

          &:first-of-type i {
            background: url('../../../assets/img/zjia.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(2) i {
            background: url('../../../assets/img/zjia.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(3) i {
            background: url('../../../assets/img/plshanchu.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(4) i {
            background: url('../../../assets/img/tina-3.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }

          &:nth-child(5) i {
            background: url('../../../assets/img/tina-4.png') no-repeat;
            width: 24px;
            height: 26px;
            background-size: 24px 26px;
            display: inline-block;
          }
        }
      }

      .message-select {
        margin-top: 52px;
      }

      .message-search {
        margin-top: 45px;
        position: relative;

        i {
          z-index: 1;
          position: absolute;
          left: 16px;
          top: 14px;
          background: url('../../../assets/img/shou.png') no-repeat;
          width: 22px;
          height: 22px;
        }
      }
    }

    .message-section {
      width: 90%;
      margin: 0 auto;

      .table_x {
        width: 86%;
        margin: 0 auto;
      }
    }
    .print_box{
      width: 90%;
      height: 100px;
      margin: 20px auto;
      background-color: #fff;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      .print_txt{
        color: #333;
        font-size: 18px;
        margin: 0px 20px;
      }
      .print_inp{
        width: 300px;
        // height: 50px;
      }
    }

  }

  .print_box_top{
    padding-top: 50px;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      .print_txt{
        color: #333;
        font-size: 18px;
      }
      .print_inp{
        width: 300px;
        // height: 50px;
      }
  }

  // 新增菜品
  .variety {
    width: 1000px;
    height: 750px;
    background: #ffffff;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 23;
    box-sizing: border-box;
    padding: 33px;
    overflow: auto;

    .variety-title {
      display: flex;
      justify-content: space-between;

      p {
        color: #333;
        font-size: 22px;
      }

      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        font-size: 15px;
        text-align: center;
        display: block;
        color: #ffffff;
        background: #cccccc;
      }
    }

    .variety-titleS {
      display: flex;
      margin-top: 34px;

      span {
        width: 4px;
        height: 26px;
        background: linear-gradient(180deg, #fc4353, #ff5e41);
        border-radius: 2px;
        display: block;
        margin-right: 11px;
      }

      p {
        font-size: 18px;
        color: #333333;
        margin-top: 2px;
      }
    }

    // .el-tabs--card .el-tabs--top
    .tabS {
      width: 100%;
      min-height: 200px;
      .el-tabs__header{ display: inline-block; }
      .el-tabs__content .el-tab-pane {
        color: #333;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  // 图片上面文字
  .thumbnail {
    margin-bottom: 20px;

    p {
      color: #333333;
      font-size: 14px;
      display: inline;
      margin: 0 6px 0 10px;
    }

    span {
      font-size: 14px;
      color: #999999;
    }
  }

  // 新增套餐
  .cuisine_select {
    width: 100%;
    max-height: 300px;

    .el-table--scrollable-x .el-table__body-wrapper {
      height: 200px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f3f3f3;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 0px #f3f3f3;
        border-radius: 10px;
        background-color: #f3f3f3;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 0px #b9b8b8;
        background-color: #b9b8b8;
      }
    }
  }

  .variety-cost {
    width: 1000px;
    height: 750px;
    background: #ffffff;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 23;
    box-sizing: border-box;
    overflow: auto;
    padding: 33px;

    .variety-cost-title {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 18px;
        color: #333;
        padding: 4px 0px 4px 10px;
        position: relative;

        &::before {
          content: '';
          width: 6px;
          height: 30px;
          background: #fc4353;
          display: block;
          border-radius: 3px;
          position: absolute;
          left: -6px;
          top: 0;
        }
      }

      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        font-size: 15px;
        text-align: center;
        display: block;
        color: #ffffff;
        background: #cccccc;
      }
    }
  }

  .Xcard {
    width: 1200px;
    height: 750px;
    background: #ffffff;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 23;
    box-sizing: border-box;
    overflow-x: auto;
    padding: 33px;

    .variety-cost-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        font-size: 18px;
        color: #333;
        padding: 4px 0px 4px 10px;
        position: relative;

        &::before {
          content: '';
          width: 6px;
          height: 30px;
          background: #fc4353;
          display: block;
          border-radius: 3px;
          position: absolute;
          left: -6px;
          top: 0;
        }
      }

      span {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
        font-size: 15px;
        text-align: center;
        display: block;
        color: #ffffff;
        background: #cccccc;
      }
    }

    .el-form {
      .costCard-foodname {
        display: flex;
        justify-content: space-between;
      }

      .costCard-foodBuZhou {
        display: flex;
        justify-content: space-between;

        .foodBuZhou-left {
          .el-input__inner {
            // width: 200px;
          }
        }

        .el-form-item__content .el-input-group {
          vertical-align: top;
          width: 200px;
        }

        .foodBuZhou-right {
          width: 50%;

          .el-form-item {
            width: 100%;
          }
        }
      }

      .costCard-xiafang {
        display: flex;
        justify-content: space-between;

        .CBR-kuandu {
          width: 50%;
        }
      }
    }

    .ingredient {
      display: flex;

      &>span {
        &:before {
          content: '*';
          color: #f56c6c;
          margin-right: 4px;
        }
      }

      span {
        font-size: 14px;
        display: inline-block;
        margin-top: 10px;
        color: #333333;
        height: 20px;
        margin-right: 20px;
      }
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>